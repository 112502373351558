<template>
  <div class="container">
    <div class="fixed">
      <!-- 静态 -->
      <InfusionTab :tabs="tabs" :active="active" @switchTab="switchTab" />
    </div>
    <div ref="tabContent" class="pTop88">
      <ApplyList
        :list="list"
        :isTel="false"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        :isInfusion="true"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        @toReservat="handleApplyAgain"
        @toExamine="handleToExamine"
        @toApplyDetail="handleApplyDetail"
        @toCenter="handleToCenter"
      />
    </div>

    <div v-if="mask" @click="cancelSearch" class="keyboard_mask"></div>
    <MedFeb />
  </div>
</template>

<script>
import InfusionTab from '@/components/infusion-tab';
import InfusionList from '@/components/infusion-list';
import ApplyList from '@/components/apply-list'
import MedFeb from '@/components/med-fab';
import api from '@/api'

export default {
  name: 'Infusion',
  components: {
    InfusionTab,
    InfusionList,
    ApplyList,
    MedFeb
  },
  data () {
    return {
      stage: 0,
      mask: false,
      searchKey: '',
      active: 0,
      tabs: [
        { key: 'ALL', title: '全部', total: 0 },
        { key: 'UNPAID', title: '待审核', total: 0 },
        { key: 'DELIVERY_PENDING', title: '预约成功', total: 0 },
        { key: 'DONE', title: '已取消/预约失败', total: 0 },
      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      tabNumObj: {
        auditCount: 0, // 待审核数量
        cancelAndFailedCount: 0,
        cancelCount: 0, // 取消数量
        successCount: 0,
      },
      pageNumber: 1,
    };
  },

  created () {
    this.getTabNum()
    // this.getList()
  },

  mounted () {
    this.$refs.tabContent.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    this.$refs.tabContent.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleToCenter (obj) {
      console.log('handleToCenter', obj.infusionCenterCode, obj)
      this.$router.push({
        path: '/center-detail',
        query: {
          id: obj.infusionCenterCode
        }
      })
    },
    handleApplyDetail (item) {
      this.$router.push({
        path: '/preengage-detail',
        query: {
          id: item.id
        }
      })
    },
    handleApplyAgain (item) {

      this.$router.push({
        path: '/appointmentApply',
        query: {
          id: item.infusionCenterCode,
          again: 1,
          applyId: item.id,
        }
      })
    },

    handleToExamine (item) {
      if (item.survey) {
        // 填写过了
        this.$router.push({
          path: '/serviceJudge',
          query: {
            id: item.id,
            fill: 1,
            infusionReservationId: item.infusionReservationId
          }
        })
      } else {
        this.$router.push({
          path: '/serviceJudge',
          query: {
            id: item.id,
            fill: 0,
          }
        })
      }

    },

    async getTabNum () {
      const res = await api.applyCount({
        pageNumber: this.pageNumber,
        pageSize: 1000,
        status: [],
        userId: JSON.parse(localStorage.getItem('userInfo') || '{}').userId
      })

      const { allCount, auditCount, successCount, cancelAndFailedCount } = res.data
      this.tabs[0].total = allCount
      this.tabs[1].total = auditCount
      this.tabs[2].total = successCount
      this.tabs[3].total = cancelAndFailedCount
    },

    mapStatus () {
      if (this.active == 0) {
        return []
      }

      if (this.active == 1) {
        return ['1']
      }

      if (this.active == 2) {
        return ['2']
      }

      if (this.active == 3) {
        return ['3', '4']
      }
    },

    // 获取列表
    async getList () {
      const res = await api.applyList({
        pageNumber: this.pageNumber,
        pageSize: 10,
        status: this.mapStatus(),
        userId: JSON.parse(localStorage.getItem('userInfo')).userId
      })

      const { totalPage, pageNumber, totalNumber } = res.data

      if (res.code === '0') {
        this.list = this.list.concat((res.data && res.data.list) || [])
        this.loading = false
      }

      // 非最后一页逻辑
      if (Number(pageNumber) < Number(totalPage)) {
        this.pageNumber = this.pageNumber + 1
        this.finished = false
      }

      // 最后一页逻辑
      if (totalPage === pageNumber) {
        this.finished = true
      }

      if (Number(totalNumber) === 0) {
        this.finished = true
      }
    },
    handleScroll () {
      if (this.$refs.tabContent.scrollTop > 0) {
        this.$refs.tabContent.scrollTo(0, 0);
      }
    },
    toSearch () {
      this.stage = 1;
      this.mask = true;
    },
    onInput (e) {
      this.searchKey = e.target.value;
    },
    onFocus () {
      this.mask = true;
    },
    onClear () {
      setTimeout(() => { this.searchKey = ''; }, 100);
    },
    // 搜索按钮触发搜索
    handleSearch () {
      this.stage = 2;
      this.mask = false;
    },

    cancelSearch () {
      this.stage = 0;
      this.mask = false;
      this.searchKey = '';
    },
    switchTab (index, item) {
      console.log(index, item, 'index');
      // 1=待审核；2=预约成功；3=预约失败;4=已取消
      this.active = index;
      // this.onRefresh();
      this.pageNumber = 1

      this.handleScroll();
      this.list = []
      this.getList()

    },
    onLoad () {
      this.loading = true
      console.log('onLoad')
      this.getList()
    },

    onRefresh () {
      console.log('refresh');
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageNumber = 1;
      this.list = [];
      // 更新tab
      this.getTabNum()
      this.onLoad();
    },

  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  margin-bottom: env(safe-area-inset-bottom);
  .fixed {
    position: fixed;
    width: 100%;
    z-index: 11;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 0 18px;
    height: 44px;
    background: #fff;
    // box-shadow: 0px 0px 8px 0px rgba(60, 107, 240, 0.1);

    .search {
      display: flex;
      padding: 2px 0;
      flex: 1;
      background: #f5f7fa;
      border-radius: 100px;
      &_icon_box {
        padding: 2px 8px 0 12px;
        height: 20px;
        line-height: 1;
      }

      &_placeholder_box {
        height: 0.7rem;
        min-height: 0.7rem;
      }

      &_icon {
        width: 20px;
        height: 20px;
      }
      &_placeholder {
        // margin-left: 16px;
        font: 200 14px/25px PingFangSC-Regular, PingFang SC;
        color: #babfc9;
      }
    }
  }
}

.headerReal {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 8px;
  width: 100%;
  height: 44px;
  background: #fff;
  box-shadow: 0px 0px 4px 4px rgba(60, 107, 240, 0.05);
  .search_outer {
    flex: 1;

    .search_component {
      display: flex;
      padding: 4px 0;
      background: #f5f7fa;
      border-radius: 100px;
      flex: 1;

      .icon_search {
        padding: 0 8px 0 12px;
        height: 20px;
        line-height: 1;
      }
      .icon_delete {
        padding: 0 4px 0 0;
        height: 20px;
        line-height: 1;
      }
      .icon_img {
        width: 20px;
        height: 20px;
      }
      .search_box {
        flex: 1;
        line-height: 1;

        .input {
          width: 250px;
          height: 20px;
          border: none;
          background: #f5f7fa;
          font-size: 14px;
          font-weight: 400px;
          line-height: 20px;
        }
        .input::placeholder {
          color: #babfc9;
        }
      }
    }
  }

  .search_text {
    width: 60px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    .text {
      font: 200 14px PingFangSC-Medium, PingFang SC;
      color: #3679f0;
    }
  }
}
.keyboard_mask {
  position: fixed;
  top: 44px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.pTop88 {
  padding-top: 42px;
}
</style>
