<template>
  <div class="tabs scroll-container">
    <div
      v-for="(item, index) in tabs"
      :key="index"
      :class="{ current: String(active) === String(index) }"
      @click="switchTab(index, item)"
      class="tab_item"
    >
      {{ item.title
      }}<span v-if="item.total || item.total === 0">({{ item.total }})</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfusionTab',
  props: {
    tabs: {
      required: true,
      default: () => [],
      type: Array,
    },
    active: {
      required: true,
      default: '0',
      type: String,
    },
  },
  data () {
    return {};
  },
  mounted () {
    const tabs = document.querySelectorAll('.tab_item');
    const container = document.querySelector('.scroll-container');

    tabs.forEach((tab) => {
      tab.addEventListener('click', (event) => {
        const { target } = event;
        const left = target.offsetLeft;
        const width = target.offsetWidth;
        const center = container.offsetWidth / 2;
        const scrollPosition = left - center + width / 2;
        container.scrollLeft = scrollPosition;
      });
    });
  },
  methods: {
    switchTab (index, item) {
      this.$emit('switchTab', index, item);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  height: 38px;
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
  margin-bottom: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  .tab_item {
    height: 20px;
    font-size: 14px;
    color: #62636c;
    line-height: 20px;
    padding: 9px 2px 13px;
    margin: 3px;
    cursor: pointer;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .current {
    color: #4486fb;
    border-bottom: 3px solid #4486fb;
    height: 14px;
    border-radius: 3px;
    transition: transform 0.3s ease;
  }
}
.scroll-container {
  width: 355px;
  height: 42px;
  // overflow: hidden;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.fontSize10 {
  font-size: 10px;
}
</style>
