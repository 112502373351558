<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
      v-model="loading"
      :finished="finished"
      @load="onLoad"
      :immediate-check="false"
      finished-text="没有更多了"
      offset="60"
    >
      <div v-for="(item, index) in list" :key="index" class="content">
        <div
          :class="{ mTop12: index === 0 }"
          @click.stop="toDetail(item)"
          class="center"
        >
          <div class="center_head">
            <span v-if="isInfusion" class="center_head_number">
              {{ item.reservationNo }}
            </span>
            <span v-if="!isInfusion" class="center_head_title">{{
              item.title
            }}</span>
            <div class="center_head_right">
              <span
                :class="{
                  orange: +item.infusionStatus === 1,
                  green: +item.infusionStatus === 2,
                  red: +item.infusionStatus === 3,
                  grey: +item.infusionStatus === 4,
                }"
                class="center_head_status"
              >
                {{ item.infusionStatusName }}
              </span>
              <span v-if="!isInfusion" class="icon_img">
                <img src="./images/right_grey@2x.png" alt="" />
              </span>
            </div>
          </div>
          <div class="center_align">
            <span class="center_align_title">
              {{ item.productName }}
            </span>
          </div>
          <div class="center_name">
            <span class="center_name_title">
              患者姓名：{{ desensitizeName(item.patientName) }}
            </span>
          </div>
          <div>
            <div class="center_contacts">
              <div class="center_contacts_way">
                联系方式：{{ desensitizePhone(item.patientPhone) }}
              </div>
              <div
                v-if="isTel"
                @click.stop="handleMakePhone(item)"
                class="center_contacts_name"
              >
                <a class="center_contacts_name_href">
                  <span class="icon_img">
                    <img src="./images/telephone@2x.png" alt="" />
                  </span>
                  <span>联系患者</span>
                </a>
              </div>
            </div>
            <div class="center_time">
              <span class="center_time_title">
                预约输注时间：
                <span class="orange">
                  {{ item.infusionDate }} {{ item.infusionTime }}
                </span>
              </span>
            </div>
            <div class="center_name">
              <span class="center_name_title">
                疾病诊断：{{ item.diagnoseName }}
              </span>
            </div>
            <div class="center_name">
              <span class="center_name_title">
                申请时间：{{ item.applicationDate }}
              </span>
            </div>
            <div v-if="+item.infusionStatus === 4" class="center_name">
              <span class="center_name_title">
                原因：{{
                  Number(item.cancelType) === 4
                    ? item.cancelComment
                    : mapCancelType(item.cancelType)
                }}
              </span>
            </div>
            <div
              v-if="item.rejecType && +item.infusionStatus === 3"
              class="center_name"
            >
              <span class="center_name_title">
                原因：{{
                  Number(item.rejecType) === 4
                    ? item.rejectComment
                    : mapRejectType(item.rejecType)
                }}
              </span>
            </div>
          </div>
          <!--按钮显隐 需要根据后端列表里参数情况而定 后续要补充-->
          <div class="center_after">
            <button
              @click.stop="toAudit(item)"
              v-if="isInfusion && +item.infusionStatus === 1"
              class="btn"
            >
              去审核
            </button>
          </div>
        </div>
      </div>
      <div v-if="list.length === 0 && !loading" class="empty_box">
        <img src="~@img/empty.png" alt="" class="empty_img" />
        <!-- <p class="empty_tip">暂无数据</p> -->
      </div>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import { desensitizePhone, desensitizeName } from '@/utils';

export default {
  name: 'InfusionList',
  props: {
    list: {
      required: true,
      default: () => [],
      type: Array,
    },
    loading: {
      required: false,
      default: false,
      type: Boolean,
    },
    finished: {
      required: false,
      default: false,
      type: Boolean,
    },
    refreshing: {
      required: false,
      default: false,
      type: Boolean,
    },
    isTel: {
      required: false,
      default: false,
      type: Boolean,
    },
    isInfusion: { // 是否是输注中心来源 默认false 为患者来源
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data () {
    return {
      desensitizePhone,
      desensitizeName,
      localLoading: this.loading,
      localFinished: this.finished,
      localRefreshing: this.refreshing,
    };
  },

  watch: {
    localLoading: {
      handler (newVal) {
        this.localLoading = newVal;
      },
      deep: true,
      immediate: true,
    },
    localFinished: {
      handler (newVal) {
        this.localFinished = newVal;
      },
      deep: true,
      immediate: true,
    },
    localRefreshing: {
      handler (newVal) {
        this.localRefreshing = newVal;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    onLoad () {
      this.$emit('onLoad'); // 上拉加载待拼接数据
    },
    onRefresh () {
      this.$emit('onRefresh');
    },
    toAudit (item) {
      console.log(item, '202');
      this.$emit('toAudit', item);
    },
    toDetail (item) {
      this.$emit('toDetail', item);
    },
    toReservat (item) {
      this.$emit('toReservat', item);
    },
    toExamine (item) {
      this.$emit('toExamine', item);
    },
    handleMakePhone (item) {
      window.location.href = `tel:${item.patientPhone}`;
    },

    mapRejectType (status) {
      if (Number(status) === 1) {
        return '患者资料不全';
      }
      if (Number(status) === 2) {
        return '预约时间冲突';
      }
      if (Number(status) === 3) {
        return '输注中心暂无法输注此药品';
      }
      if (Number(status) === 4) {
        return '其他';
      }
    },
    mapCancelType (status) {
      // 1=计划有变，不想去了；2=预约时间调整，想重新预约；3=输注中心通知无法接待；4=其他
      if (Number(status) === 1) {
        return '计划有变，不想去了';
      }
      if (Number(status) === 2) {
        return '预约时间调整，想重新预约';
      }
      if (Number(status) === 3) {
        return '输注中心通知无法接待';
      }
      if (Number(status) === 4) {
        return '其他';
      }
    },
  },
};

</script>

<style lang ='scss' scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  .center {
    width: 348px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(60, 107, 240, 0.1);
    border-radius: 8px;
    margin: 8px 13px 0 13px;
    &_head {
      margin: 0px 13px;
      height: 42px;
      border-bottom: 0.2px solid rgba(60, 107, 240, 0.1);
      font-family: PingFangSC-Regular, PingFang SC;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px 8px 0 0;
      &_number {
        font-size: 14px;
        font-weight: 400;
        color: #90949e;
        padding-top: 3px;
      }
      &_title {
        font-size: 16px;
        font-weight: 500;
        color: #3a3a3a;
        padding-top: 3px;
      }
      &_right {
        display: flex;
        align-items: center;
      }
      &_status {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        padding-top: 3px;
      }
      .orange {
        color: #f36218;
      }
      .green {
        color: #20c0b4;
      }
      .grey {
        color: #a8a8a8;
      }
      .red {
        color: #fa3e3e;
      }
      .icon_img {
        width: 14px;
        height: 14px;
        margin-top: 3px;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    &_align {
      padding: 10px 13px 2px 13px;
      display: flex;
      justify-content: space-between;
      &_title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3a3a3a;
      }
      &_num {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }
    &_name {
      padding: 2px 13px;
      &_title {
        height: 18px;
        font-size: 14px;
        color: #666666;
        line-height: 18px;
        word-break: break-all;
      }
    }
    &_contacts {
      padding: 2px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_way {
        height: 18px;
        font-size: 14px;
        color: #666666;
        line-height: 18px;
      }

      &_name {
        display: flex;
        align-items: center;
        .icon_img {
          width: 14px;
          height: 14px;
          margin: 0 2px 0 0;
          img {
            width: 14px;
            height: 14px;
          }
        }
        font-size: 14px;
        color: #3c6bf0;
        &_href {
          color: #3c6bf0 !important;
          text-decoration: none !important;
          cursor: pointer;
        }
      }
    }

    &_time {
      padding: 2px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_title {
        height: 18px;
        font-size: 14px;
        color: #666666;
        line-height: 18px;
        .orange {
          color: #f36218;
        }
      }
    }
    &_after {
      margin: 5px 13px 0 13px;
      height: 48px;
      border-top: 0.2px solid rgba(60, 107, 240, 0.1);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &_status {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #f36218;
        padding-top: 3px;
      }
    }
  }
}

.btn {
  width: 80px;
  height: 30px;
  line-height: 25px;
  border-radius: 15px;
  border: 1px solid #f36218;
  font-weight: 500;
  font-size: 14px;
  background: #f36218;
  color: #ffffff;
}
.btn_w90 {
  width: 90px;
}
.btn_w110 {
  width: 110px;
}
.plian {
  color: #f36218;
  background: #ffffff;
  border: 1px solid #f36218;
}
.btn_l4 {
  margin-left: 4px;
}

.mTop12 {
  margin-top: 12px !important;
}

.empty_box {
  height: calc(100% - 88px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.empty_img {
  width: 100%;
  padding-top: 100px;
}
.empty_tip {
  font-size: 14px;
  color: #90949e;
  text-align: center;
}
</style>
