<template>
  <div @click="toHome" class="basic">
    <img src="~@img/return_home.png" />
  </div>
</template>

<script>

export default {

  name: 'MedFab',
  data () {
    return {

    };
  },
  methods: {
    toHome () {
      this.$router.push({
        path: '/home',
      });
    },
  },
};
</script>
<style lang ='scss' scoped>
.basic {
  position: fixed;
  z-index: 999;
  right: 5px;
  bottom: 94px;

  img {
    width: 75px;
    height: 75px;
  }
}
</style>
